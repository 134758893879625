.border-box {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
#calendar {
  float: left;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  background: white;
  width: 1260px;
  border: solid 1px #ccc;
  margin-bottom: 10px;
}
#calendar > div.header {
  float: left;
  width: 100%;
  background: #2875c7;
  height: 40px;
  color: white;
}
#calendar > div.header > * {
  height: 40px;
  line-height: 40px !important;
  display: inline-block;
  vertical-align: middle;
}
#calendar > div.header > i {
  float: left;
  width: 40px;
  font-size: 1.125em;
  font-weight: bold;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0 10px;
  cursor: pointer;
}
#calendar > div.header > i.fa-angle-left {
  text-align: left;
}
#calendar > div.header > i.fa-angle-right {
  text-align: right;
  margin-left: -40px;
}
#calendar > div.header > span {
  float: left;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding-left: 50px;
  margin-left: -40px;
  text-align: center;
  padding-right: 40px;
  color: inherit;
}
#calendar > div.week {
  float: left;
  width: 100%;
  border-top: solid 1px #ccc;
}
#calendar > div.week:first-child {
  border-top: none;
}
#calendar > div.week > span.day {
  float: left;
  width: 14.28571429%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-left: solid 1px #ccc;
  height: 100%;
  font-size: 1.2em;
  text-align: left;
  /*height: 30px;*/
  /*line-height: 30px !important;*/
  display: inline-block;
  vertical-align: middle;
  background: white;
  cursor: pointer;
  color: black;
  padding-left: 10px;
}

#calendar > div.week > span.day p {
  margin: 0px 0px 1px;
}

.today .dayHeaderNum {
  background-color: black;
}

.resetMarginsPadding {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

#calendar > div.week > span.day:first-child {
  border-left: none;
}
#calendar > div.week > span.day.today {
  background: #e3f2ff;
}
#calendar > div.week > span.day.different-month {
  color: #c0c0c0;
  border: none;
}
#calendar > div.week > span.day.selected {
  background: #2875c7;
  color: white;
}
#calendar > div.week.names > span {
  color: #2875c7;
  font-weight: bold;
  text-align: center;
}

.dayHeaderCompany {
}

.dayHeaderAlerts {
}

.dayHeaderNum {
  float: right;
  background-color: gray;
  text-align: center;
  color: white;
  font-weight: 900;
  font-size: medium;
}

.Cal__Weekdays__root {
  background-color: #9e9e9e !important;
}

.Cal__Weekdays__day {
  color: black;
}
.calendarDayShift {
  font-style: italic;
}
.calendarDayStationLabel {
  font-weight: 700;
}
.nav_button {
  background-color: blue;
  background: rgb(30, 87, 153);
  background: -moz-linear-gradient(
    top,
    rgba(30, 87, 153, 1) 0%,
    rgba(41, 137, 216, 1) 50%,
    rgba(32, 124, 202, 1) 51%,
    rgba(125, 185, 232, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(30, 87, 153, 1) 0%,
    rgba(41, 137, 216, 1) 50%,
    rgba(32, 124, 202, 1) 51%,
    rgba(125, 185, 232, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(30, 87, 153, 1) 0%,
    rgba(41, 137, 216, 1) 50%,
    rgba(32, 124, 202, 1) 51%,
    rgba(125, 185, 232, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8',GradientType=0 );
}
.nav-tabs > li {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a90329+0,8f0222+65,6d0019+88 */
  background: rgb(169, 3, 41); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(169, 3, 41, 1) 0%,
    rgba(143, 2, 34, 1) 65%,
    rgba(109, 0, 25, 1) 88%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(169, 3, 41, 1) 0%,
    rgba(143, 2, 34, 1) 65%,
    rgba(109, 0, 25, 1) 88%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(169, 3, 41, 1) 0%,
    rgba(143, 2, 34, 1) 65%,
    rgba(109, 0, 25, 1) 88%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a90329', endColorstr='#6d0019',GradientType=0 ); /* IE6-9 */
}

.Cal__Container__root {
  width: 100% !important;
}
