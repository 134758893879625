.AccordionNavigation {
  @keyframes slideIn {
    from {
      left: -500px;
    }
    to {
      left: 0;
    }
  }
  @keyframes slideOut {
    from {
      left: 0;
    }
    to {
      left: -500px;
    }
  }
  .menu-icon {
    color: #fff;
    font-size: 1.45em;
    margin-bottom: 8px;
    margin-top: 2px;
    cursor: pointer;
    border: 1px solid white;
    border-radius: 2px;
    padding: 2px 4px;
    padding-top: 3px;
  }
  .menu-list {
    animation: slideIn;
    animation-duration: 0.7s;
    list-style: none;
    padding-top: 20px;
    padding-left: 25px;
    position: absolute;
    left: -10px;
    z-index: 2000;
    width: 100%;
    min-height: calc(100vh - 93px); //everything minus the header
    opacity: 0.95;
    background-color: #161855;
    background: linear-gradient(#161855 0%, #2989d8 27%, #207cca 97%, #969abf 100%);
    background: -webkit-linear-gradient(#161855 0%, #2989d8 27%, #207cca 97%, #969abf 100%);
    background: -o-linear-gradient(#161855 0%, #2989d8 27%, #207cca 97%, #969abf 100%);
    .menu-option {
      color: #fff;
      cursor: pointer;
      font-weight: bold;
      font-size: 1.2em;
      font-style: italic;
      margin-bottom: 20px;
    }
    .menu-option:hover {
    }
  }
  .hide-menu {
    animation: slideOut;
    animation-duration: 0.7s;
    left: -500px;
  }
  .display-none {
    display: none;
  }
}
