.confirmButtons {
  margin: 15px 0px 10px;
  text-align: center;
  .formButton {
    border-radius: 5px;
    margin: 0px 8px;
    font-weight: bold;
    background: white;
    color: #009ad0;
    border: 2px solid #009ad0;
    box-shadow: 2px 2px 5px #a1a1a1;
  }
  .formButton.nd-button.green {
    border: 2px solid #28a745;
    color: #28a745;
  }
  .formButton.nd-button.red {
    border: 2px solid #dc3545;
    color: #dc3545;
  }
}
