.OEv2 {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 10vh);
  overflow: hidden;
  margin: 0;
  font-size: 1.1em;
  padding: 10px;
  .topPanel {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-radius: 6px 6px 0 0;
    width: 100%;
    height: 30px;
    color: white;
    padding-right: 10px;
    background: linear-gradient(to top, #161855 0%, #2989d8 87%, #207cca 94%, #969abf 100%);
    span {
      font-size: 16px;
      cursor: pointer;
    }
    span:hover {
      font-size: 20px;
    }
  }
  .objectTreeContainer {
    width: 20%;
    transition: width 0.7s;
    background: white;
    border: 1px solid #333;
    border-radius: 6px 6px 0 0;
    overflow: auto;
  }
  .objectTree {
    padding: 10px 2px 10px 10px;
    .node {
      margin-bottom: 5px;
      cursor: pointer;
    }
    .bold {
      font-weight: bold;
    }
  }
  .rightSide {
    width: 79.5%;
    transition: width 0.7s;
    display: flex;
    flex-flow: column;
    height: 100%;
    .topPanel {
      flex: 0 0 30px;
    }
    .onDeck {
      flex-grow: 0;
      flex-shrink: 4;
      flex-basis: auto;
      //height: 30%;
      //transition: height 0.5s;
      background: white;
      border: 1px solid #333;
      overflow: auto;
    }
    .navigation {
      flex: 0 0 40px;
      //height: 6%;
      background: white;
      border: 1px solid #333;
    }
    .workingArea {
      flex: 1 2 600px;
      //height: calc(59% - 30px);
      //transition: height 0.5s;
      background: white;
      overflow: auto;
      border: 1px solid #333;
    }

    // .onDeck:before {
    //   background-image: linear-gradient(to bottom, rgba(255,255,255,0),rgba(255,255,255,.6) 50%, rgba(255,255,255, 1) 100%);
    //   opacity: 0;
    //   transition: opacity .9s;
    //   content: "";
    //   position: absolute;
    //   z-index: 3;
    //   top: 25%;
    //   bottom: 0;
    //   left: 20%;
    //   pointer-events: none;
    //   width: 80%;
    //   height: 100px;
    // }
    // .onDeck.unscrolled:before {
    //   opacity: 1;
    // }
  }
}

.treeShrunk {
  .objectTreeContainer {
    width: 3%;
    min-width: 36px;
    .objectTree {
      display: none;
    }
  }

  .rightSide {
    width: 96.5%;
  }
}

.onDeckShrunk {
  .rightSide {
    .onDeck {
      height: 8%;
      min-height: 46px;
      overflow: auto;
      .onDeckPanel {
        padding-top: 5px;
        .onDeckButtonbar {
          display: none;
        }
      }
    }
    .workingArea {
      height: calc(86% - 34px);
    }
  }
}

.OEv2.simple {
  .rightSide {
    .workingArea {
      height: calc(100% - 30px);
    }
  }
}

@media (max-width: 749px) {
  .OEv2 {
    height: auto;
    max-height: 100%;
    display: block;
    .objectTreeContainer {
      display: block;
      max-height: 70%;
      width: 100%;
      justify-content: inherit;
      transition: height 0.7s;
      margin-bottom: 4px;
    }
    .rightSide {
      display: block;
      width: 100%;
      height: auto;
      .navigation {
        height: 30px;
        .navLabel {
          display: none;
        }
      }
      .onDeck {
        margin-bottom: 4px;
        display: block;
        height: 50%;
        .deckObject {
          width: 100%;
          max-width: 100%;
          .removeFromOnDeck {
            float: right;
          }
        }
        .deckObject:hover {
          color: inherit;
          border-top: 5px solid #207cca;
        }
        .deckObject:nth-child(2) {
          margin-top: 10px;
        }
      }
      .workingArea {
        display: block;
        flex: none;
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        padding-bottom: 0;
      }
    }
  }
  .treeShrunk {
    .objectTreeContainer {
      height: 40px;
      overflow: hidden;
    }
  }
  .onDeckShrunk {
    .rightSide {
      .onDeck {
        overflow: hidden;
        height: 10px;
        min-height: 10px;
      }
    }
  }
  .OEv2.simple {
    .rightSide {
      .workingArea {
        height: 100%;
      }
    }
  }
}
