.records-wrapper {
  .record_flex {
    display: flex;
    justify-content: space-between;
  }
  .record_wrapper_width {
    width: 400px;
  }
  .record {
    vertical-align: super;
    margin-bottom: 10px;

    .record-label {
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      //color: #344054;
      margin-right: 5px !important;
    }
  }

  .flex_col {
    flex-direction: column;
  }

  .records-row-flex {
    display: flex;
    border-bottom: 1px solid #eaecf0;
    justify-content: space-between;
  }
  .details {
    margin: 0;
    display: block;
    padding: 0;
    color: inherit;
    background-color: inherit;
    border: none;
    font-family: inherit;
    font-size: 12px !important;
    word-break: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow: visible;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: #a0a0a0;
  }
}
