.RibbonWrapper, .RibbonWrapper-netduty {
  display: flex;
  margin-bottom: 5px;
  padding: 0px 5px;
  padding-bottom: 10px;
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
}
.RibbonWrapper-netduty {
  padding-top: 10px;
}
.RibbonWrapper{
  background: #bd6b58;
}
.nd-button {
  border: 0px !important;
}
.Ribbonbg {
  background: #646569 !important;
  min-height: calc(100vh - 64px); //64px is from header height
}
.Ribbonbg-netduty {
  background: #cccccc !important;
  min-height: calc(100vh - 64px); //64px is from header height
}
.vertical-line {
  padding-right: 15px;
}
