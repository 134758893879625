.dialog-box {
  border: 1px solid #3e3e3e;
  background: #fff;
  border-radius: 2px;
  box-shadow: 5px 5px 10px #3e3e3e;
  min-width: 300px;
  max-width: 100vw;
  max-height: 100vh;
  position: fixed;
  top: 70px;
  right: 10px;
  z-index: 1202; // 1 more than Material UI's AppBar

  /* Variables */
  $vin-blue: #161855;
  $vin-light-blue: linear-gradient(to top, #161855 0%, #2989d8 60%, #207cca 94%, #969abf 100%);
  $vin-green: #60b044;
  $vin-red: linear-gradient(to bottom, #a90329 0%, #8f0222 65%, #6d0019 100%);
  $vin-red-hover: linear-gradient(to bottom, #a90329 100%, #8f0222 100%, #6d0019 100%);

  td[valign='top'] {
    vertical-align: top;
  }

  .display-none {
    display: none;
  }

  .box-header {
    background: $vin-blue;
    border-bottom: 1px solid black;
    color: white;
    padding: 5px;
    font-weight: bold;

    .box-controllers {
      line-height: 20px;
      height: 20px;
      display: flex;

      .header-icon {
        line-height: 20px;
      }

      .dialogbox-title {
        justify-content: flex-start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
        margin-right: 10px;
        margin-left: 5px;
      }

      .controllers-right {
        display: flex;
        flex-wrap: nowrap;
        position: absolute;
        right: 10px;

        .controller {
          border-radius: 3px;
          font-weight: bold;
          height: 20px;
          width: 40px;
          line-height: 20px;
          margin-left: 5px;
        }
      }

      .maximize-button,
      .minimize-button {
        background: $vin-light-blue;
      }

      .close-button {
        background: $vin-red;
      }

      .close-button:hover {
        background: $vin-red-hover;
      }
    }
  }

  .box-header:hover {
    cursor: move;
  }

  .box-body {
    margin: 2px;
    min-height: inherit;
    max-height: 90vh;
    overflow-y: auto;

    .formContent {
      width: 100%;

      .rdt.rdtOpen {
        .rdtPicker {
          position: relative;
          top: 0;
          left: 0;
        }
      }
    }

    .tabs-header {
      border-radius: 2px;
      background: $vin-blue;
      display: flex;
      flex-wrap: wrap;
      padding: 5px 2px 0 2px;

      .single-tab-header {
        background: none;
        background-color: #efefef;
        border: 1px solid gray;
        border-radius: 2px 2px 0 0;
        border-bottom-color: transparent;
        padding: 5px;
        margin: 0 1px 0 0;
        color: #555555;
      }

      .active {
        background: $vin-red !important;
        color: white;
      }

      .single-tab-header:hover {
        background: $vin-red;
        color: white !important;
        cursor: pointer;
      }
    }

    .tabs-content {
      padding: 10px;
    }

    .records {
      padding: 5px 2px;
    }
  }
}

.is-mobile {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .form {
    .formContent {
      width: calc(100% - 10px);

      .formColumns {
        .formColumn {
          padding: 0 !important;
        }
      }

      .formSection {
        .sectionContent {
          .inputField {
            padding-bottom: 5px;

            textarea {
              width: 80%;
            }
          }

          div.Select.customSelect.multiple {
            min-width: 80%;
          }
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .dialog-box {
    top: 0;
    right: 0;
    max-width: 100vw;
    overflow-y: scroll;

    .box-header {
      .box-controllers {
        height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .controllers-right {
          position: relative;
          right: auto;
        }
      }
    }
  }
}
