.scheduleBuilder {
  .alert-popup {
    border-radius: 7px;
    box-shadow: 3px 3px 20px 2px #000;
    text-align: center;
    padding: 20px;
    position: fixed;
    z-index: 5;
    right: 40%;
    left: 40%;
    bottom: 20px;
    .alert-icon {
      margin-right: 5px;
    }
  }

  .cardHeader {
    border-radius: 10px 10px 0 0;
  }

  .control-panel {
    text-align: right;
    margin: 0;
    position: relative;
    .panel-button {
      position: absolute;
      top: 3px;
      right: 0;
      border-radius: 2px;
      font-weight: bold;
      background: #161855;
      border: 1px solid #282966;
      padding: 2px 5px;
    }
  }

  .filters-wrapper {
    .filters-body {
      padding: 0;
      position: relative;
      .clear-all {
        display: inline-block;
        position: absolute;
        right: 10px;
      }
    }
  }

  .form-container {
    border: 1px solid #3e3e3e;
    border-radius: 5px;
    margin: 10px;
    .form-header {
      border-bottom: 1px solid #3e3e3e;
      font-weight: bold;
      padding: 5px 5px 5px 10px;
    }
  }
  .schedule-box {
    margin-bottom: 10px;
    width: 49%;
    margin-right: 0.5%;
    margin-left: 0.5%;

    .loading-icon {
      margin-left: 10px;
    }
  }

  .side-by-side-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
  }

  .beige-wrapper {
    display: block;
    margin-bottom: 10px;
  }

  .no-padding {
    padding: 0 !important;
  }

  .plain-select {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 200px;
  }

  .plain-select select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    line-height: normal;
    background: #f2f4f7;
    background: linear-gradient(#fff, #f2f4f7);
    border: 1px solid #c8c8c8;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    display: block;
    font-weight: bold;
    height: 38px;
    margin: 0;
    padding: 8px 20px 7px 10px;
    width: 100%;
  }

  .plain-select select:focus {
    background: #f9f9f9;
    outline: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), inset 0 0 7px rgba(100, 100, 100, 0.3);
    border-color: #00c0f3;
  }
  .plain-select ::-ms-expand {
    display: none;
  }
  .plain-select:after {
    content: '';
    position: absolute;
    z-index: 2;
    right: 8px;
    top: 50%;
    margin-top: -3px;
    height: 0;
    width: 0;
    border-top: 6px solid #161855;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    pointer-events: none;
  }

  input.small-input,
  .input-date {
    width: 200px;
  }

  .input-date {
    background: #fff repeat-x 0 0;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    color: #9f9f9f;
    display: block;
    height: 38px;
    margin: 0;
  }

  .dropdown-label {
    display: block;
    color: #505050;
  }

  /* Calendar */
  .react-datepicker .react-datepicker-container .react-datepicker-top {
    background: linear-gradient(to top, #161855 0%, #2989d8 87%, #207cca 94%, #969abf 100%);
    color: #fff;
    border-top: 1px solid #161855;
    border-left: 1px solid #161855;
    border-right: 1px solid #161855;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  i.icon-rc-datepicker_calendar {
    color: #161855;
  }
  /* end of Calendar */

  .configuration-body {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    .beige-wrapper {
      margin-right: 30px;
    }
  }
  .inline-block {
    display: inline-block;
  }
  .filter {
    padding: 0 10px;
    .filter-label {
      vertical-align: middle;
      margin-right: 2px;
    }
  }
  .block {
    display: block;
  }
  .max-width {
    min-width: 100%;
  }
  .checkbox-no-filter {
    margin-bottom: 2px;
  }
}
