div#topContainer.header.DAILYROSTER {
  background: linear-gradient(to bottom, #050744 0%, #161855 8%, #2989d8 57%, #d8d8d8 97%);

  #headerLogo {
    max-height: 50px;
    position: absolute;
  }
  .innerContainer {
    .container-fluid {
      .nd-row {
        .nav-tabs {
          background: linear-gradient(to top, #d8d8d8 0%, #939393 39%, #787878 94%, #686868 97%);
          li {
            margin-bottom: -2px;
          }
          a {
            background: #000a33;
            background: #555;
            color: white;
            font-weight: bold;
          }
          .active a {
            background: #f8f8f8;
            color: #555555;
          }
        }
      }
    }
  }
}

div.content.DAILYROSTER {
  background-color: #f8f8f8;
}
@media (min-width: 507px) {
  div#topContainer.header.DAILYROSTER {
    #headerLogo {
      max-height: 35px;
    }
  }
}
@media (min-width: 770px) {
  div#topContainer.header.DAILYROSTER {
    #headerLogo {
      padding-bottom: 10px;
      max-height: 80px;
    }
  }
}
