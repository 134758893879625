.nd-dropdown {
  display: inline-block;
  .nd-container {
    position: relative;
    display: inline-block;

    .dropdown {
      position: absolute;
      top: 100%;
      right: 20px;
      width: 220px;
      width: 220px;
      z-index: 5500;
      border: 1px solid rgba(0, 0, 0, 0.04);
      box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
      background: #161855;
      border-radius: 5px;
      color: white;
      display: none;
      font-weight: bold;
      text-align: left;
    }

    .dropdown.open {
      display: block;
    }

    .nd-button {
      display: flex;
      justify-content: center;
      outline: none;
      span.glyphicon {
        margin-left: 5px;
      }
    }

    .nd-button.selected {
      outline: none;
      color: #161855 !important;
      .title {
        text-decoration: underline;
      }
    }

    .nd-button:active {
      outline: none;
    }

    ul {
      list-style: none;
      padding: 10px;
      margin: 0;
      border: 1px solid #333;
      border-top: none;
      border-radius: 5px;

      li {
        text-align: left;
        padding: 8px 12px;
        border: none;
        margin-bottom: 2px;
        border-right: none !important;
        cursor: pointer;
      }

      li:hover {
        color: #161855;
        text-decoration: underline;
      }

      li.selected {
        text-decoration: underline;
        color: #161855;
      }
    }
  }
}

.nd-dropdown.transparentBtn {
  .nd-container {
    font-size: 1.1em;
    .nd-button {
      background: none;
      border: none;
      color: #207cca;
    }
    .dropdown {
      background: white;
    }
  }
}
