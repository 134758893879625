.navContent {
  .leftNav {
    float: left;
    .leftButton {
      cursor: pointer;
      margin-left: 10px;
      .navLeft {
        margin-right: 10px;
      }
    }
  }
  .centerNav {
    text-align: center;
    .onDeckObject {
      .objectLabel {
        font-weight: bold;
        font-size: 1.3em;
      }
    }
  }
  .rightNav {
    float: right;
    .rightButton {
      cursor: pointer;
      margin-right: 10px;
      .navRight {
        margin-left: 10px;
      }
    }
  }
}

@media (max-width: 880px) {
  .navContent {
    .leftNav {
      .text {
        display: none;
      }
    }
    .centerNav {
    }
    .rightNav {
      .text {
        display: none;
      }
    }
  }
}
