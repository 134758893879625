//TODO: abstract this further
.duties {
  .duty {
    .dutyIcon {
      cursor: pointer;
    }
    .shiftContainer {
      .shiftIcon {
        cursor: pointer;
      }
    }
  }
}
@import './ShiftCreate/AssignByStaff.scss';
