.content-box {
  margin: 10px 0px;

  &:first-child {
    margin-top: 0px !important;
  }

  &:last-child {
    margin-bottom: 0px !important;
  }

  .noscroll-y {
    overflow: visible !important;
  }

  background: #fff;
  width: 100%;
  border-radius: 5px;

  .font-size-12 {
    font-size: 12px;
  }

  .align-right {
    float: right;
  }

  .align-left {
    float: left;
  }

  .no-border {
    margin: -10px;
  }

  .display-none {
    display: none;
  }

  .calendar-alert {
    color: red !important;
  }

  abbr[title],
  abbr[data-original-title] {
    cursor: pointer;
    border-bottom: none !important;
    text-decoration: none !important;
  }

  .mini-calendar {
    margin: 0 -10px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    color: black;

    .small-calendar-day {
      font-size: 1.3em;
      padding: 7px;
    }

    .small-calendar-day:hover {
      text-decoration: underline;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .content-box-header {
    background: #161855;
    border-radius: 5px 5px 0 0;
    border: none;
    color: #fff;
    font-weight: bold;
    padding: 7px 3px 7px 10px;
    min-height: 33px;
    font-size: 1.1em;

    .content-box-title {
      float: left;
    }

    .header-icon {
      float: left;
      margin-right: 3px;
    }

    .expandable-button {
      text-align: right;
      padding-right: 5px;
    }

    .expandable-button:hover {
      cursor: pointer;
    }

    .nav-calendar {
      width: 25%;
    }

    .link:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  /* end of content-box-header */

  .content-box-body {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    padding: 10px;
    padding-bottom: 10px;
    max-width: 100%;
    font-size: 1.1em;

    .top-buttonbar {
      text-align: right;
      padding: 0px 5px;
      margin-bottom: 2px;
    }

    .button-image {
      padding: 0;
      margin: 0 3px;

      .darkblue {
        color: #161855;
      }

      .darkgreen {
        color: darkgreen;
      }

      .darkred {
        color: #8f0222;
      }
    }

    .quick-link-wrapper {
      position: relative;

      .quick-link {
        border-radius: 0 0 3px 3px;
        background: #3c3c3c;
        color: white;
        cursor: pointer;
        font-weight: bold;
        opacity: 0.6;
        padding: 5px 10px;
        position: absolute;
        top: 0;
        right: 10px;
      }

      .quick-link:hover {
        opacity: 0.8;
      }
    }

    .no-horizontal-padding {
      padding-right: 0;
      padding-left: 0;
    }

    .single-row {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      width: 100%;
      margin: 10px 0px 20px 0px;
    }
  }

  /* end of content-box-body */

  .max-height-body {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (min-width: 500px) and (max-width: 749px) {
  .content-box {
    // width: 330px;

    .mini-calendar {
      .small-calendar-day {
        font-size: 1.2em;
        padding: 5px;
      }
    }
  }
}

@media (min-width: 750px) {
  .content-box {
    // width: 630px;

    .mini-calendar {
      .small-calendar-day {
        font-size: inherit;
        padding: 0;
      }
    }

    .content-box-header {
      font-size: inherit;
    }

    .content-box-body {
      font-size: inherit;
      color: black;
    }
  }
}

.content-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
  padding: 10px 20px;
  column-gap: 20px;
  border-radius: 5px 5px 0px 0px;

  h4 {
    font-weight: 700 !important;
  }
  h4 span {
    font-size: inherit !important;
  }

  .shift-A {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #2780e3;
  }
}

.vertical-buttons button {
  width: 100%;
  height: 50px;
  background: #2780e3;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.border {
  border: 1px solid silver;
}
