.APIMap {
  min-height: 100%;
  width: 100%;

  .content-wrapper {
    display: flex;
    /* Overwriting contentCard's border-radius */
    .cardHeader {
      border-radius: 10px 10px 0 0;
    }
    .form-apimap {
      margin: 10px 0;
      min-height: 250px;
    }

    .left-content {
      min-width: 300px;
      .selected {
        font-weight: bold;
      }
      .toggle-buttons-group {
        list-style: none;
        margin: 0;
        padding: 10px;
        .toggle-buttons-item {
          margin-bottom: 5px;
          padding: 0;
        }
        .toggle-buttons-item:hover {
          cursor: pointer;
          font-weight: bold;
        }
      }
    }

    .right-content {
      width: 100%;
      .records {
        margin-top: 20px;
        .record-button {
          margin-right: 5px;
        }
        .record-button:hover {
          font-size: 1.1em;
          cursor: pointer;
        }
        .record-wrapper {
          display: flex;
          flex-wrap: wrap;
          padding: 30px 10px;
          width: 100%;
        }

        .record-group-box {
          border: 1px solid #1c1c1c;
          border-radius: 5px;
          box-shadow: none;
          min-width: 30%;
          margin: 0 10px 20px 0;
          padding-bottom: 10px;

          .cardHeader {
            border-bottom: 1px solid #2c2c2c;
            background-color: #fff;
            color: #1c1c1c;
          }
          .cardBody {
            padding: 10px;
          }

          .group-record-title {
            font-weight: bold;
            text-transform: uppercase;
          }

          .record-group-list {
            list-style: none;
            margin: 0;
            padding: 0;

            .record-group-item {
              padding: 5px 0px;
            }
          }
        } /* End of .record-group-box */
      } /* End of .records */
    } /* End of .right-content */
  }
}
