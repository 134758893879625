.onDeckPanel {
  padding: 10px;
  display: inline-block;
  width: 100%;
  .deckObject {
    display: inline-block;
    cursor: pointer;
    min-width: 100px;
    max-width: 300px;
    border: 1px solid #7c7c7c;
    border-top: 5px solid #207cca;
    border-radius: 6px;
    padding: 5px 5px 5px 10px;
    margin-right: 6px;
    margin-bottom: 6px;
  }
  .deckObject.selected {
    font-weight: bold;
    color: #333;
    border-top: 5px solid #333;
  }
  .deckObject:hover {
    color: #333;
    border-top: 5px solid #333;
  }
  .removeFromOnDeck {
    margin-left: 8px;
  }
  .removeFromOnDeck:hover {
    color: #000;
  }
  .onDeckButtonbar {
    margin-top: -6px;
    float: right;
  }
}
