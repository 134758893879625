.objectTree {
  .pushButton {
    position: relative;
    float: right;
    margin-right: 10px;
    cursor: pointer;
  }
  .treeContainer {
    padding-bottom: 10px;
    .editableObject {
      .objectLabel.selected {
        font-weight: bold;
      }
      .objectLabel.selectable {
        cursor: pointer;
      }
      .expandButton {
        margin-right: 5px;
        cursor: pointer;
      }
    }

    .treeNodeContainer.leaf {
      margin-left: 16px;
    }

    .treeNodeContainer {
      .buttonContainer {
        display: inline-block;
        margin-right: 5px;
      }
      .branchContainer {
        padding-left: 10px;
      }
      .branchContainer.hidden {
        display: none;
      }
    }
  }
}
