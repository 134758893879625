.action {
  background-color: #990033;
  color: #000000;
}
.ndAlert {
  font-weight: 700;
  color: #ff0000;
}
.ndHidden {
  display: none;
}
.note {
  background-color: #ffff66;
  color: #000000;
}
.timeclock {
  background-color: #726afb;
  color: #000000;
}
.today {
  border-color: #ff3333;
  border-width: 1px;
  border-style: groove;
}
.companyRed {
  /** Missing values **/
  background-color: #ff0000;
  color: #000000;
}
.companyGreen {
  /** Missing values **/
  background-color: #006600;
  color: #000000;
}
.companyGold {
  background-color: #ffcc00;
  color: #000000;
}
.companyBlue {
  background-color: #3366ff;
  color: #000000;
}
.companyBlack {
  background-color: #000000;
  color: #ffffff;
}
div.tabs-header.shift {
  background-color: #214670 !important;
  color: #000000;
}
.glyphicon.lightblue {
  color: #009ad0;
}
/** Operation's Board Container Header **/
/** ContentBox and DialogBox Header **/
/** Object Editor **/
div.formContent.admin-notes {
  background-color: #fdfb8c;
}
div.content-box-header.pto,
div.box-header.pto,
input.nd-button.pto,
button.btn.btn-default.nd-button.pto,
button.btn.btn-default.nd-button.pto,
button.calendar-button.pto,
.calendar-button.MuiPaper-root.pto,
div.draggable-item.pto,
div.top-panel > div.control.pto,
div.top-panel > div.filters.pto,
div.contentCard > div.cardHeader.pto,
div.operations-board > .operations-board-body > .column > .container-wrapper > div.container-header.pto {
  background-color: #726afb;
  color: white;
  height: 50px;
}
div.content-box-header.leave,
input.nd-button.leave,
button.btn.btn-default.nd-button.leave,
button.calendar-button.leave,
.calendar-button.MuiPaper-root.leave,
div.top-panel > div.control.leave,
div.top-panel > div.filters.leave,
div.contentCard > div.cardHeader.leave,
div.draggable-item.leave,
div.operations-board > .operations-board-body > .column > .container-wrapper > div.container-header.leave {
  background-color: #82ddf0;
  color: #000000;
}
div.content-box-header.holiday,
div.draggable-item.holiday,
input.nd-button.holiday,
button.btn.btn-default.nd-button.holiday,
button.calendar-button.holiday,
.calendar-button.MuiPaper-root.holiday,
div.top-panel > div.control.holiday,
div.top-panel > div.filters.holiday,
div.contentCard > div.cardHeader.holiday,
div.operations-board > .operations-board-body > .column > .container-wrapper > div.container-header.holiday {
  background-color: #009900;
  color: #000000;
}
div.content-box-header.shift,
div.draggable-item.shift,
div.box-header.shift,
input.nd-button.shift,
button.btn.btn-default.nd-button.shift,
button.calendar-button.shift,
.calendar-button.MuiPaper-root.shift,
div.top-panel > div.control.shift,
div.top-panel > div.filters.shift,
div.contentCard > div.cardHeader.shift,
div.operations-board > .operations-board-body > .column > .container-wrapper > div.container-header.shift {
  background-color: #214670;
  color: #ffffff;
}
div.content-box-header.exchange,
div.draggable-item.exchange,
div.box-header.exchange,
input.nd-button.exchange,
button.btn.btn-default.nd-button.exchange,
button.calendar-button.exchange,
.calendar-button.MuiPaper-root.exchange,
div.top-panel > div.control.exchange,
div.top-panel > div.filters.exchange,
div.contentCard > div.cardHeader.exchange,
div.operations-board > .operations-board-body > .column > .container-wrapper > div.container-header.exchange {
  background-color: #5e4352;
  color: #ffffff;
}
div.content-box-header.bid,
div.draggable-item.bid,
div.contentCard > div.cardHeader.bid,
input.nd-button.bid,
button.btn.btn-default.nd-button.bid,
button.calendar-button.bid,
.calendar-button.MuiPaper-root.bid,
div.top-panel > div.control.bid,
div.top-panel > div.filters.bid,
div.operations-board > .operations-board-body > .column > .container-wrapper > div.container-header.bid {
  background-color: #82b4cc;
  color: #000000;
}
div.content-box-header.lightblue,
div.box-header.lightblue,
div.draggable-item.lightblue,
div.contentCard > div.cardHeader.lightblue,
input.nd-button.lightblue,
button.btn.btn-default.nd-button.lightblue,
button.calendar-button.lightblue,
.calendar-button.MuiPaper-root.lightblue,
div.top-panel > div.control.lightblue,
div.top-panel > div.filters.lightblue,
div.operations-board > .operations-board-body > .column > .container-wrapper > div.container-header.lightblue {
  background-color: #009ad0;
  color: #fff;
}
div.content-box-header.timeclock,
div.draggable-item.timeclock,
div.box-header.timeclock,
input.nd-button.timeclock,
button.btn.btn-default.nd-button.timeclock,
button.calendar-button.timeclock,
.calendar-button.MuiPaper-root.timeclock,
div.top-panel > div.control.timeclock,
div.top-panel > div.filters.timeclock,
div.contentCard > div.cardHeader.timeclock,
div.operations-board > .operations-board-body > .column > .container-wrapper > div.container-header.timeclock {
  background-color: #00ccff;
  color: #000000;
}
div.content-box-header.event,
div.draggable-item.event,
div.box-header.event,
input.nd-button.event,
button.btn.btn-default.nd-button.event,
button.calendar-button.event,
.calendar-button.MuiPaper-root.event,
div.top-panel > div.control.event,
div.top-panel > div.filters.event,
div.contentCard > div.cardHeader.event,
div.operations-board > .operations-board-body > .column > .container-wrapper > div.container-header.event {
  background-color: #7d7abc;
  color: #000000;
}
div.content-box-header.signup,
div.draggable-item.signup,
div.box-header.signup,
input.nd-button.signup,
button.btn.btn-default.nd-button.signup,
button.calendar-button.signup,
.calendar-button.MuiPaper-root.signup,
div.top-panel > div.control.signup,
div.top-panel > div.filters.signup,
div.contentCard > div.cardHeader.signup,
div.operations-board > .operations-board-body > .column > .container-wrapper > div.container-header.signup {
  background-color: #6457a6;
  color: #000000;
}
div.content-box-header.callout,
div.draggable-item.callout,
div.box-header.callout,
div.top-panel > div.control.callout,
div.top-panel > div.filters.callout,
input.nd-button.callout,
button.btn.btn-default.nd-button.callout,
button.calendar-button.callout,
.calendar-button.MuiPaper-root.callout,
div.contentCard > div.cardHeader.callout,
div.operations-board > .operations-board-body > .column > .container-wrapper > div.container-header.callout {
  background-color: #cf7885;
  color: #000000;
}

/** SVGS **/
.svg-img.svg-img-white,
span.svg-img-white {
  filter: invert(1);
}
.svg-img.svg-img-lightblue,
span.svg-img-lightblue {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
}
.svg-img.svg-img-blue,
span.svg-img-blue {
  filter: invert(0.5) sepia(1) saturate(100) hue-rotate(240deg);
}
.svg-img.svg-img-nd,
span.svg-img-nd {
  filter: invert(0.5) sepia(1) saturate(100) hue-rotate(230deg);
}
.svg-img.svg-img-red,
span.svg-img-red {
  filter: invert(0.5) sepia(1) saturate(100) hue-rotate(390deg);
}
.svg-img.svg-img-lightgreen,
span.svg-img-lightgreen {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(90deg);
}
.svg-img.svg-img-orange,
span.svg-img-orange {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(0deg);
}
.svg-img.svg-img-purple,
span.svg-img-purple {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(270deg);
}
.svg-img.completed,
span.completed {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(90deg);
}
.svg-img.pending,
span.pending {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
}

/** Details **/
pre.shift-details,
pre.details {
  font-size: 0.88em !important;
  font-style: italic;
}
span.shift-label {
  font-weight: 600;
  font-size: 0.9em;
}

/** Calendar Mday View **/
div.day-panel.today {
  background-color: #cdc4bc;
}
/** Form Builder **/
.max-width {
  width: 100%;
}

div.panel.panel-default.warning {
  background-color: #ffc107;
  color: #000;
}

div.panel.panel-default.success {
  background-color: #28a745;
  color: #fff;
}

div.panel.panel-default.danger {
  background-color: #dc3545;
  color: #fff;
}

div.nd-button.ribbon21 {
  background-color: #161855;
  color: #fff;
}
div.nd-button.ribbon25 {
  background-color: #1a1d65;
  color: #fff;
}
div.nd-button.ribbon30 {
  background-color: #1f227a;
  color: #fff;
}
div.nd-button.ribbon95 {
  background-color: #ebebfa;
  color: #000;
}

.svg-img-lightblue {
  color: #3bacf3;
}