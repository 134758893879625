.calendar {
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 0px 20px;
  .top-panel {
    .control {
      background: #161855;
      border: 1px solid #eee;
      border-radius: 3px 0 0 3px;
      color: white;
      cursor: pointer;
      float: left;
      min-height: 30px;
      width: 120px;
      padding: 5px;
      .control-label {
        font-weight: bold;
      }
      .control-icon {
        margin-left: 2px;
        float: right;
      }
    }
    .filters {
      min-height: 30px;
      border: 1px solid #eee;
      border-radius: 0 3px 3px 0;
      float: left;
      background: #f8f8f8;
      display: flex;
      flex-wrap: wrap;
      padding: 5px 5px 5px 10px;
      .filter-wrapper {
        display: block;
        margin: 0 5px;
        .filter-input {
          margin-top: 0;
          vertical-align: text-bottom;
        }
        .filter-label {
          margin: 0 0 0 2px;
        }
      }
    }
  }
  abbr[title],
  abbr[data-original-title] {
    cursor: pointer;
    border-bottom: none !important;
    text-decoration: none !important;
  }
  .calendar-button {
    background: #161855;
    border: 1px solid #969abf;
    border-radius: 5px;
    margin: 5px 5px 0 0;
    font-weight: bold;
    font-size: 1.1em;
  }
  .calendar-button.MuiPaper-root {
    li {
      border-bottom: 1px solid #333;
    }
    li:hover {
      font-weight: bold;
    }
  }
  // .calendar-button:focus{
  //   background: #207cca;
  // }
  .nav-options {
    float: right;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    .scope-section {
      margin-left: 100px;
    }
  }
  .calendar-content {
    clear: both;
    margin-top: 20px;
    .calendar-header {
      border-radius: 10px 10px 0 0;
      display: flex;
      flex-wrap: nowrap;
      .prev-calendar {
        margin-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .current-calendar {
        font-size: 1.3em;
        font-weight: bold;
        margin-right: auto;
        margin-left: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .next-calendar {
        margin-right: 10px;
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .prev-calendar:hover,
      .next-calendar:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    } /* end of calendar-header */

    .calendar-body {
      padding-left: 30px;
      .calendar-body-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .calendar-days-header {
          display: flex;
          width: 100%;
          text-align: center;
        }
        .panel-body.dayview-btn-link:hover {
          background: #efefff;
          cursor: pointer;
        }
      } /* end of calendar-body-wrapper*/
    } /* end of calendar-body */
  } /* end of calendar-content*/
  .display-none {
    display: none !important;
  }
} /* end of calendar */

@media (max-width: 769px) {
  .calendar {
    min-width: 100%;
    max-width: 100%;
    .top-panel {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 5;
      margin-bottom: 10px;
      button {
        float: right;
        margin-right: 0;
      }
      .control {
        min-height: 30px;
        width: 120px;
        padding: 5px;
        padding-left: 10px;
      }
      .filters {
        padding: 5px;
        display: block;

        .filter-wrapper {
          display: block;
          margin: 5px 5px 15px 5px;
          .filter-input {
            margin-top: 0;
            vertical-align: text-bottom;
          }
          .filter-label {
            margin: 0 0 0 2px;
          }
        }
      }
    }
    .calendar-content {
      .calendar-header {
        position: relative; /* so the position sticky on filters can work */
        .next-calendar {
          margin-right: 2px;
        }
        .prev-calendar {
          margin-left: 2px;
        }
        .current-calendar,
        .next-calendar,
        .prev-calendar {
          font-size: 0.9em;
        }
      }
      .calendar-body {
        overflow: auto;
        .calendar-days-header > div,
        .day-panel {
          width: auto;
          min-width: 140px;
        }
        .calendar-body-wrapper {
          min-width: 1000px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .calendar {
    min-width: 100%;
    max-width: 100%;
    .nav-options {
      float: left;
      display: inline;
      margin-bottom: 10px;
      .scope-section {
        margin-left: 0;
      }
    }
    .top-panel {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 5;
      margin-bottom: 10px;
      button {
        float: right;
        margin-right: 0;
      }
      .control {
        min-height: 30px;
        width: 120px;
        padding: 5px;
        padding-left: 10px;
      }
      .filters {
        padding: 5px;
        .filter-wrapper {
          display: block;
          margin: 5px 5px 15px 5px;
          .filter-input {
            margin-top: 0;
            vertical-align: text-bottom;
          }
          .filter-label {
            margin: 0 0 0 2px;
          }
        }
      }
    }
    .calendar-content {
      .calendar-header {
        position: relative; /* so the position sticky on filters can work */
        .next-calendar {
          margin-right: 2px;
        }
        .prev-calendar {
          margin-left: 2px;
        }
        .current-calendar,
        .next-calendar,
        .prev-calendar {
          font-size: 0.9em;
        }
      }
      .calendar-body {
        overflow: auto;
        .calendar-days-header > div,
        .day-panel {
          width: auto;
          min-width: 140px;
        }
        .calendar-body-wrapper {
          min-width: 1000px;
        }
      }
    }
  }
}
