.commandPanel {
  height: calc(100vh - 158px);
  .header-icon {
    display: inline-block;
    line-height: 20px;
    margin-right: 2px;
  }
  .left {
    float: left;
    min-width: 250px;
    height: 100%;
    .leftPanel {
      height: 100%;
      .buttonContainer {
        .leftMenuButton {
          display: block;
          border-radius: 5px;
          margin: 4px 4px;
          font-weight: bold;
        }
      }
    }
  }
  .right {
    height: 100%;
    .top {
      height: 150px;
      border: solid 1px gray;
      position: relative;

      .cardExpandButton {
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 5px;
        color: white;
      }
    }
    .bottom {
      height: calc(100% - 150px);
      border: solid 1px gray;
      .resultMessage {
        padding: 10px 20px;
      }
    }
  }
}

.commandPanel.showForm {
  .top {
    height: calc(100% - 150px);
  }
  .top-no-bottom {
    height: calc(100%);
  }
  .bottom {
    height: 150px;
  }
}

.commandPanel.showObjects {
  .right {
    .top {
      height: 30%;
      border: solid 1px gray;
    }
    .middle {
      height: 32px;
      border: solid 1px gray;
    }
    .bottom {
      height: calc(70% - 32px);
      border: solid 1px gray;
    }
  }
}

@media (max-width: 440px) {
  .commandPanel {
    display: block;
    height: 100%;
    .left {
      float: none;
      display: block;
      min-width: 100%;
      height: auto;
      .leftPanel {
        height: auto;
        min-height: 100px;
        max-height: 250px;
        .buttonContainer {
          margin-top: 5px;
          .leftMenuButton {
            display: inline-block;
            margin: 0 10px 10px 0;
          }
        }
      }
    }
    .right {
      display: block;
      height: 100%;
      .top {
        height: 100px;
        .cardExpandButton {
          cursor: pointer;
          color: white;
        }
      }
      .bottom {
        height: calc(100% - 150px);
        .resultMessage {
          padding: 10px 20px;
        }
      }
    }
  }

  .commandPanel.showForm {
    .right {
      .top {
        height: auto;
      }
      .bottom {
        height: 150px;
      }
    }
  }

  .commandPanel.showObjects {
    .right {
      .top {
        height: 100px;
      }
      .middle {
        height: 32px;
      }
      .bottom {
        height: calc(70% - 32px);
      }
    }
  }
}
