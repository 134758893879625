.editor {
  height: calc(100vh - 158px);
  width: 100%;
  display: flex;
  .header-icon {
    display: inline-block;
    line-height: 20px;
  }
  .left {
    height: 100%;
    min-width: 200px;
    max-width: 300px;
    .treeView {
      height: 100%;
      width: 100%;
      border: solid 1px gray;
    }
  }
  .right {
    height: 100%;
    width: 100%;
    min-width: 300px;
    .onDeck {
      height: 30%;
      border: solid 1px gray;
    }
    .navigation {
      min-height: 32px;
      border: solid 1px gray;
    }
    .workingArea {
      height: calc(70% - 32px);
      border: solid 1px gray;
    }
    .workingArea.simple {
      height: 100%;
    }
  }
}

@import './OnDeck.scss';
@import './NavArea.scss';
@import './WorkingArea.scss';

@media (max-width: 440px) {
  .editor {
    height: 100%;
    display: block;
    font-size: 1.1em;
    .left {
      height: auto;
      min-width: auto;
      max-width: 100%;
      width: 100%;
      .treeView {
        min-height: 100px;
        max-height: 250px;
        margin: 0;
        .treeContainer {
          .treeNodeContainer {
            margin-top: 5px;
          }
          .treeNodeContainer.leaf {
            margin-top: 8px;
            margin-bottom: 8px;
          }
        }
      }
      margin-bottom: 5px;
    }
    .right {
      height: auto;
      .onDeck {
        height: auto;
        min-height: 80px;
        max-height: 300px;
        max-height: 200px;
        margin: 0;
      }
      .navigation {
        margin: 5px 0;
        .navRight,
        .navLeft {
          font-size: 1.4em;
        }
      }
      .workingArea {
        min-height: 300px;
        margin: 0;
      }
    }
  }
}
