.onDeckContent {
  cursor: pointer;
  .buttonContainer {
    float: right;
    padding: 5px;
    padding-top: 0;
  }
  .onDeckObject {
    margin-right: 10px;
    border: 1px solid;
    padding: 5px;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 10px;
    .objectLabel {
      margin-right: 5px;
    }
    .objectLabel.selected {
      font-weight: bold;
    }
    .glyphicon {
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .tempHoverEffect:hover {
    font-weight: bold;
  }
}
