.login, .login-netduty {
  margin-top: 60px;
  min-height: calc(100vh - 180px);
  .login-form {
    margin: 0 auto;
  }

  #button-sign-in {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  #button-reset-password {
    padding-left: 0;
    padding-right: 0;
  }
  .ndAlert {
    font-size: 1.3em;
  }
}

.login-netduty {
  background: url('../img/calendar_mouse.jpg') no-repeat;
  padding-top: 60px;
  margin-top: 0;
  min-height: calc(100vh - 64px);
}
@media (max-width: 440px) {
  .login {
    margin-top: 30px;
  }
}
