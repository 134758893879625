.WorkingArea {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
  .workingAreaTabs {
    text-align: right;
    width: 100%;

    .navTabs {
      padding: 0;
      margin-top: 10px;
      text-decoration: none;

      li {
        cursor: pointer;
        display: inline-block;
        padding: 5px 10px;
        margin-right: 10px;
        color: #207cca;
        font-weight: bold;
        font-size: 1.2em;
        border-right: 2px solid #ccc;
      }

      li:last-child {
        border-right: none;
      }

      li.selected {
        color: #161855;
        text-decoration: underline;
      }
    }
  }
  .workingAreaBody {
    display: block;
    width: 100%;
  }
  .workingAreaBody.tabsContent.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .workingAreaBody.invisible {
    display: none;
  }
}
