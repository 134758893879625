.assignByStaff {
  .formRow {
    padding: 6px 0px;

    .checkbox {
      margin: 0px;
    }

    .shiftTimes {
      padding-bottom: 12px;
      .timeSelectLabel {
        margin: 0 0 3px;
      }
    }
    .shiftTimes:last-child {
      padding-bottom: 0px;
    }
  }
}
