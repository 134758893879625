::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 6px;
}

.admin {
  @import 'APIMap/APIMap.scss';
  @import './ScheduleBuilder/ScheduleBuilderApp.scss';
  @import 'UserEditor/UserEditor.scss';
}
