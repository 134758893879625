//TODO: use flexbox

.tabArea {
  width: 100%;
  height: calc(100% - 82px);
  .tabLabels {
    //this tab style is mimicking the tabs in the header
    .tab {
      display: inline-block;
      overflow: hidden;
      margin-bottom: -14px;
    }
    /* The SELECTED class .selected is now handled at /styles/netduty.css
    ** because of the different Tab styles that we've introduced. */
  }
  .tabContent.hidden {
    display: none;
  }
  .tabContent {
    min-height: 82vh;

    .endpoint {
      background-color: white;
      padding: 20px;
      margin: 3px;
      border-radius: 10px;
      box-shadow: 2px 2px 2px #888888;
    }
  }
}

@media (max-width: 440px) {
  .tabArea {
    .tabLabels {
      .tab {
        margin-bottom: 0;
      }
    }
    .tabContent {
      min-height: 79vh;
    }
  }
}
