.TreePanel {
  .treeToolbar {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 20px;
    .treeButtonbar {
      padding-right: 10px;
      text-align: right;
      font-size: 1.2em;
    }
    .pushButton {
      font-size: 1.2em;
      cursor: pointer;
    }
  }
}
