/* Variables */
$vin-blue: #5bb7db;
$vin-green: #60b044;
$vin-red: #ff0000;

/* Styles */
a {
  text-decoration: none;
}
body {
  font: 14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.4em;
  color: #4d4d4d;
  background-color: #f8f8f8;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 300;
}

span {
  font-size: 13px;
}
body,
li,
p,
a,
label {
  font-size: 13px;
}

td {
  padding: 12px;
  font-size: 13px;
}

h2 {
  color: $vin-blue;
}

input.small {
  width: 46px;
}

.rbc-calendar {
  flex: 1;
  min-height: 550px;
}

.shiftCreateModal select {
  width: auto;
  display: inline-block;
  margin-right: 5px;
}

.shiftEditModal select {
  width: auto;
  display: inline-block;
  margin-right: 5px;
}

#headerLogo {
  max-height: 40px;
  padding-top: 5px;
}

#headerRow {
  background: none;
  /*background-color: rgb(181, 26, 38);*/
}

div#version-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  max-width: 400px;
}
.version-choice {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 2px #838383;
  margin-left: auto;
  margin-right: auto;
  width: 48%;
  text-align: center;
  cursor: pointer;
  padding: 30px 0;
}
.version-choice:hover > h2 {
  font-weight: 400;
}
.version-choice:hover {
  opacity: 0.9;
  box-shadow: 3px 3px 5px #636363;
}
.version-choice {
  color: #133f50 !important;
}
.version-selection-wrapper {
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
.selection-title {
  display: block;
  font-size: 20px;
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
}

.center-block {
  margin: 0 auto;
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

.topPaddingMd {
  padding-top: 20px;
}

.bottomPaddingSm {
  padding-bottom: 10px;
}

.hCenter {
  margin: 0 auto;
}

.innerContainer {
  float: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.content {
  /* 15px to eliminate -15px from .row, plus 15px to match top menu */
  padding-right: 20px;
  padding-left: 20px;
}

.dutyButtons {
  float: right;
  margin-left: 10px;
}

.floatRight {
  float: right;
}

.blackBorder {
  border: 2px solid #d3d3d3;
  border-radius: 4px;
}

.monthControlButton {
  display: inline;
}

.monthControlButton:hover {
  color: white;
  cursor: pointer;
}

.insidePaddingSm {
  padding: 5px;
}

.shiftCreateModal {
  width: 90vw;
  max-width: 1000px;
}

.shiftEditModal {
  width: 90vw;
}

.bigMonthDisplay {
  font-family: 'Kanit', sans-serif;
  font-weight: 900;
  font-size: 50px;
  color: black;
}

.glyphicon-refresh-animate {
  -animation: spin 0.7s infinite linear;
  -webkit-animation: spin2 0.7s infinite linear;
}

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.miniCalendar {
  /*padding-top: 68px;*/
  margin-right: auto;
  margin-left: auto;
}

.dutyIcon {
  width: 20px;
  height: 20px;
  display: inline;
}

.stationsContainer {
  background-color: white;
}

.stationPanel {
  border: none;
}

.stationPanel .list-group-item {
  border: none;
}

.shiftIcon {
  width: 20px;
  height: 20px;
  margin-left: 7px;
}

.shiftContainer {
  padding-left: 30px;
}

.dutyLabel.alert {
  color: red;
}

.viewWeekButtonContainer {
  position: relative;
  width: 0px;
  height: 0px;
}

.viewWeekButton {
  position: absolute;
  left: -17px;
  top: 0px;
  height: 80px;
}

.prevWeekBtn {
  position: absolute;
  left: -17px;
  top: 0px;
  height: 50px;
}

.nextWeekBtn {
  position: absolute;
  left: -17px;
  top: 51px;
  height: 50px;
}

.viewMonthButton {
  position: absolute;
  height: 50px;
}

.editShiftBtn {
  margin-top: 3px;
  margin-left: 3px;
  width: 80px;
}

.calendarTitle {
  text-align: center;
}

.monthPrevButton {
  display: inline;
  cursor: pointer;
}

.monthNextButton {
  display: inline;
  cursor: pointer;
}

.dayContainer {
  min-height: 100px;
}

.dayAlertMsg {
}

.dutyLabel {
}

.shiftLabel {
}

.calendarDayShift {
}

.calendarDayContainer {
}

.calendarDayStationLabel {
}

.dayTitle {
  text-align: center;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

#Layer_1___2063570356 {
  height: 30px;
  display: block;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: rgb(218, 218, 218);
}

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');
@import url('https://fonts.googleapis.com/css?family=Kanit:300,400,700,900');
