body {
  /**
  *CONTENT CARD STYLING
  *
  *TODO: consider making this a component
  **/
  #app {
    overflow-x: hidden;
  }

  background-color: #ccc !important;

  
  abbr {
    border-bottom: none !important;
  }

  abbr[title] {
    text-decoration: none !important;
  }

  .contentCard {
    padding-bottom: 40px;
    margin: 3px;
    border-radius: 10px;
    box-shadow: 3px 2px 9px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    position: relative;

    .cardHeader {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      z-index: 3;
      background-color: #161855;
      min-width: 100%;
      color: white;
      font-weight: bold;
      text-align: center;
      height: 24px;
      line-height: 24px;
      font-size: 16px;
    }

    .cardBody {
      padding: 5px 10px;
    }
  }

  /*
  ** Adding a better looking Scroll Bar for Webkit Browsers
  */
  .editorSection {
    overflow: auto;
  }
  .editorSection::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }
  .editorSection::-webkit-scrollbar-track {
    background: lightgrey;
    border-radius: 20px;
    margin: 2px;
  }
  .editorSection::-webkit-scrollbar-track-piece,
  .editorSection::-webkit-scrollbar-corner {
    border-radius: 10px;
  }
  .editorSection::-webkit-scrollbar-thumb {
    background: darkgrey;
    border-radius: 10px;
  }
  .editorSection::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  /*
  * quick fix for DEV-104
  * TODO: move this elsewhere
  */
  .form-control {
    padding: 0 10px;
  }

  /*
* This REALLY needs to go somewhere else. reorganize the css as soon as possible. consider using sass
* also we should add custom class instead of using bootstrap defaults like this
*
* FIXING DEV-67 and DEV-68
*/
  .panel.panel-default {
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #161855;
    .panel-heading {
      background-color: #161855;
      color: white;
      font-weight: bolder;
    }
  }

  .header-link {
    margin-left: 10px !important;
    font-style: italic;
  }
  .header-link:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  /* Default ND Button for Tabs */
  .nd-button {
    background: #161855;
    border: 1px solid #969abf;
    border-radius: 5px;
    color: #fff;
    margin: 5px 5px 0 0;
    font-weight: bold;
    font-size: 13px !important;
    padding: 5px 10px;
    span {
      font-size: 13px;
    }
  }

  /* Default ND Button for Tabs - TEXT */
  .nd-button.text {
    background: none;
    border: none;
    color: #161855;
    font-weight: bold;
    box-shadow: none;
    -webkit-box-shadow: none;
    -o-box-shadow: none;
    -moz-box-shadow: none;
  }
  .netduty-tabs:visited,
  .netduty-tabs{ 
    background: #161855;
    border: 1px solid #969abf;
    border-radius: 5px;
    color: #fff;
    margin: 5px 5px 0 0;
    min-height: unset;
    font-weight: bold;
    font-size: 13px !important;
    padding: 10px !important;
    span {
      font-size: 13px;
    }
  }
  .netduty-tabs:hover,
  .Mui-selected.netduty-tabs{
    background: #fff;
    color: #000;
  }

  button.nd-button.formbutton.btn {
    background: none;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #c1c1c1;
    color: #2989d8;
    margin: 5px 5px 0 0;
    font-weight: bold;
    font-size: 14px;
    padding: 2px 10px;
    outline: none !important;
    outline-offset: none !important;
  }

  button.nd-button.formbutton.btn:hover {
    box-shadow: 2px 2px 5px #a1a1a1;
    border: 1px solid #a1a1a1;
  }

  /* Styling Bootstrap Buttons */
  .btn.btn-sm.btn-default.selected,
  .btn.btn-sm.btn-default:hover,
  .btn.btn-sm.btn-default:active,
  .btn.btn-sm.btn-default:visited,
  .nd-button.selected,
  button.nd-button.btn.btn-default.selected,
  .nd-button:hover,
  .nd-button:active,
  .nd-button:visited {
    background: #f8f8f8;
    color: #555;
    outline: none;
    cursor: pointer;
    .svg-img-white {
      filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
    }
  }

  .svg-img.svg-img-transparent {
    opacity: 0;
  }

  .btn.btn-sm.btn-default.text.selected,
  .btn.btn-sm.btn-default.text:hover,
  .btn.btn-sm.btn-default.text:active,
  .btn.btn-sm.btn-default.text:visited,
  .nd-button.text:hover,
  .nd-button.text:active,
  .nd-button.text:visited {
    background: none;
    color: #2989d8;
    text-decoration: underline;
    outline: none;
  }

  .htmlEndpointContainer {
    min-height: calc(100vh - 210px);
    padding: 1rem 2rem;
    > b {
      margin: 1rem 0;
      display: block;
    }
    table {
      // width: 100%;
      border-collapse: collapse;
      text-align: center;
      vertical-align: middle;
      th,
      td {
        // border: 1px solid #ddd;
        // padding: 8px 14px;
      }
    }
  }

  @media (min-width: 767px) {
    /*header style*/
    .menuTabs {
      // background:none;
      // background-image: url("../img/header_background_main.jpg");
      // background-size: 100%;
      // overflow: hidden;
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 15px;
      width: 100%;
      max-width: none;
      width: 100%;
      .nd-row {
        border-radius: 5px;
        .col-xs-3 {
          h5 {
            font-weight: bolder;
          }
        }
        .nav-tabs {
          padding-top: 8px;
          padding-left: 8px;
          margin-left: -15px;
          margin-right: -15px;
          background: -webkit-linear-gradient(bottom, #161855 0%, #2989d8 87%, #207cca 94%, #969abf 100%);
          background: -o-linear-gradient(bottom, #161855 0%, #2989d8 87%, #207cca 94%, #969abf 100%);
          background: linear-gradient(to top, #161855 0%, #2989d8 87%, #207cca 94%, #969abf 100%);
          li {
            margin-right: 1px;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            padding: 5px 15px;
            overflow: hidden;
            cursor: pointer;
            line-height: 1.5;
            padding-bottom: 5px;
            font-size: 14px !important;
            color: #555555;
            background: none;
            background-color: #efefef;
            border: 1px solid grey;
            border-bottom-color: transparent;
          }
          .active {
            background: linear-gradient(to bottom, #a90329 0%, #8f0222 65%, #6d0019 88%);
            background-color: transparent;
            color: white;
            border: none;
          }
        }
      }
      #headerRow {
        background: none;
      }
    }
  }
}
@import '../components/Admin/Admin.scss';
@import '../components/FormBuilder/FormBuilder.scss';
@import '../components/Dashboard/Dashboard.scss';
@import '../components/Reports/Reports.scss';
@import '../components/Common/Common.scss';
@import '../components/Day/Day.scss';
@import '../components/DutyRoster/DutyRoster.scss';
@import '../components/AppLayout/AppLayout.scss';
@import '../components/Login.scss';
@import '../components/Calendar/Calendar.scss';
@import '../components/Calendar/DayPanel.scss';
@import '../components/DayView/DayView.scss';
@import '../components/OperationsBoard/OperationsBoard.scss';
@import './object_colors.css';
@import './dailyRosterTheme.scss';

// Overriding some of these CSS files - PJW Removed. Makes styling harder
p,
a,
input {
  font-size: 14px;
}
