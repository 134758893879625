.Records {
  min-height: 100%;
  width: 100%;

  abbr[title],
  abbr[data-original-title] {
    cursor: pointer;
    border-bottom: none !important;
    text-decoration: none !important;
  }
  .record-control-panel {
    float: right;
    margin-top: 5px;
    margin-right: 20px;
    .react-datepicker-component {
      display: inline-block;
    }
    div {
      display: flex;
      .selector-label {
        font-weight: bold;
        display: inline-block;
        margin-right: 5px;
        font-size: 13px;
      }
    }
    .react-datepicker-component {
      width: unset;
    }
  }


  .content-wrapper {
    display: flex;
    /* Overwriting contentCard's border-radius */
    .cardHeader {
      border-radius: 10px 10px 0 0;
    }
    .left-content {
      min-width: 300px;
      .selected {
        font-weight: bold;
      }
      .toggle-buttons-group {
        list-style: none;
        margin: 0;
        padding: 10px;
        .toggle-buttons-item {
          margin-bottom: 5px;
          padding: 0;
        }
        .toggle-buttons-item:hover {
          cursor: pointer;
          font-weight: bold;
        }
      }
    }

    .right-content {
      width: 100%;

      .filters-body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .filter-wrapper {
        margin-right: 10px;
      }
      .records {
        .record-button {
          margin-right: 5px;
        }
        .record-button:hover {
          font-size: 1.1em;
          cursor: pointer;
        }
        .record-wrapper {
          display: flex;
          width: 100%;
        }

        .record-group-box {
          border: 1px solid #1c1c1c;
          border-radius: 5px;
          box-shadow: none;
          width: 100%;
          margin: 0 10px 20px 0;
          padding-bottom: 10px;

          .cardHeader {
            border-bottom: 1px solid #2c2c2c;
            background-color: #fff;
            color: #1c1c1c;
          }
          .cardBody {
            padding: 10px;
          }

          .group-record-title {
            font-weight: bold;
            text-transform: uppercase;
          }

          .record-group-list {
            list-style: none;
            margin: 0;
            padding: 0;

            .record-group-item {
              display: flex;
              padding: 5px 0px;
            }
          }
        } /* End of .record-group-box */
        .cell {
          margin-left: 10px;
        }
      } /* End of .records */
    } /* End of .right-content */
  }

  .Select--multi .Select-multi-value-wrapper {
    max-width: 500px;
  }
  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    width: 180px;
    overflow: hidden;
  }
  .Select-arrow-zone,
  .Select--multi .Select-clear-zone {
    vertical-align: top !important;
    padding-top: 8px;
  }
  .Select.customSelect.multiple.Select--multi.is-clearable.is-searchable {
    min-width: auto;
  }
  .customSelect {
    max-width: 100%;
    width: 230px;
    text-align: left;
    .Select-menu-outer {
      .Select-menu {
        .optLabel {
          padding: 5px 10px;
          cursor: pointer;
          .detailText {
            font-size: 0.8em;
            font-style: italic;
          }
        }
        .optLabel:hover {
          background-color: #e6f4fe;
        }
      }
    }
  }
  .filters-body {
    .filter-wrapper {
      overflow: visible;
    }
  }
}
