.dayView {
  padding: 0px 20px;
  .top-panel {
    cursor: pointer;
    .control {
      background: #161855;
      border: 1px solid #161855;
      border-radius: 3px 0 0 3px;
      color: white;
      float: left;
      min-height: 30px;
      width: 120px;
      padding: 5px;
      .control-label {
        font-weight: bold;
      }
      .control-icon {
        margin-left: 2px;
        float: right;
      }
    }
    .filters {
      min-height: 30px;
      border: 1px solid white;
      border-radius: 0 3px 3px 0;
      float: left;
      background: white;
      display: flex;
      flex-wrap: wrap;
      padding: 5px 5px 5px 10px;
      .filter-wrapper {
        display: block;
        margin: 0 5px;
        .filter-input {
          margin-top: 0;
          vertical-align: text-bottom;
        }
        .filter-label {
          margin: 0 0 0 2px;
        }
      }
    }
  }
  .columns-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .day-single-column {
      width: 100%;
    }
  }
  .display-none {
    display: none !important;
  }
}

@media (min-width: 500px) and (max-width: 749px) {
  //Tablet View
  .dayView {
    .columns-wrapper {
      .col-dvsidebar {
        width: 49%;
      }
      .col-ls {
        width: 49%;
      }
      .col-dutyroster {
        width: 49%;
        min-width: 220px;
        max-width: 400px; //style for Duty Roster
      }
      .col-rs {
        width: 49%;
        min-width: 300px; //style for rs
      }
      .day-single-column {
        margin-right: 10px;
      }
    }
  }
}

@media (min-width: 750px) {
  //Desktop View
  .dayView {
    .columns-wrapper {
      .col-dvsidebar {
        width: 20%;
        min-width: 220px;
        max-width: 250px; //style for SideBar
      }
      .col-ls {
        width: 20%;
        min-width: 250px; //style for ls
      }
      .col-dutyroster {
        width: 35%;
        min-width: 220px;
        max-width: 500px; //style for Duty Roster
      }
      .col-rs {
        width: 20%;
        min-width: 300px; //style for rs
      }
      .day-single-column {
        margin-right: 10px;
      }
    }
  }
}
