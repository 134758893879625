.reports {
  padding: 10px;
  height: calc(100vh - 120px);

  .cardExpandButton {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;
    color: white;
  }

  .left {
    float: left;
    width: 25%;
    height: 100%;
    position: relative;
    .treeView {
      height: 100%;
      overflow: auto;
      border: solid 1px gray;
      .cardExpandButton.collapsed {
        right: 6px;
      }
      .top-buttonbar.collapsed {
        display: none;
      }
    }
  }

  .right {
    width: 75%;
    float: right;
    height: 100%;
    .designer {
      height: calc(100% - 80px);
      border: solid 1px gray;
      overflow: auto;
      position: relative;
    }
    .header {
      height: 40px;
      border: solid 1px gray;
      overflow: scroll;
    }
    .resultsContainer {
      height: 40px;
      border: solid 1px gray;
      overflow: auto;
      padding: 0px;

      .results {
        padding: 5px;
      }
    }
  }
}
.reports.leftClosed {
  .left {
    .cardExpandButton {
      color: black;
    }
    .collapsedLabel {
      white-space: nowrap;
      transform: rotate(270deg);
      position: absolute;
      top: 83px;
      left: -30px;
      font-weight: bold;
    }
    width: 35px;
  }
  .right {
    width: calc(100% - 35px);
  }
}

.reports.designerClosed {
  .right {
    .designer {
      height: 32px;
      text-align: center;
      .collapsedLabel {
        font-weight: bold;
      }
    }
    .resultsContainer {
      height: calc(100% - 72px);
    }
  }
}

.reports.hideHeader {
  .right {
    .designer {
      height: calc(100% - 40px);
    }
  }
}

.reports.designerClosed.hideHeader {
  .right {
    .designer {
      height: 32px;
    }
    .resultsContainer {
      height: calc(100% - 32px);
    }
  }
}
