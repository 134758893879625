.customToolTip {
  width: 16px;
  height: 16px;
  max-height: 16px;
  max-width: 16px;
  line-height: 16px;
  margin-left: 2px;
  margin-right: 1px;
  display: inline-block;
  // vertical-align: top;
}
