.login-container {
  height: calc(100vh - 40px);
  display: flex;
  justify-content: center;
}

.form-section {
  margin-top: 40px;
  background-color: #fafafa;
  height: 380px;
  width: 100%;
  max-width: 460px;
  padding: 20px;
  border-radius: 4px;
}

.form-body {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.form-title {
  margin-top: 20px;
  text-align: center;
  font-weight: 600;
  color: #3c3c3c;
  font-size: 1.3em;
}

.login-description {
  color: #4c4c4c;
  font-size: 16px;
  line-height: 1.3em;
  max-width: 300px;
  margin: auto;
  margin-bottom: 20px;
  text-align: justify;
}

.input-form {
  align-self: center;
  width: 100%;
  margin-top: 20px;
  max-width: 300px;
  height: 42px;
  font-size: 16px;
  padding: 5px 5px 5px 10px;
  -webkit-appearance: none;
  background: #fff;
  box-shadow: none;
  border: 1px solid #c3c3c3;
  border-radius: 2px;
}

.login-button {
  border-radius: 5px;
  margin: auto;
  font-weight: bold;
  font-size: 1.1em;
  padding: 5px 15px;
  background: white;
  color: #009ad0;
  border: 2px solid #009ad0;
  box-shadow: 2px 2px 5px #a1a1a1;
  width: auto;
  margin-top: 40px;
}

.login-button:hover {
  background: white;
  border: 2px solid #0078b0;
  box-shadow: 2px 2px 5px #717171;
}

.login-button.inactive,
.login-button.inactive:hover {
  background: #c3c3c3;
  color: #3c3c3c;
  cursor: default;
  border: 2px solid #c3c3c3;
  box-shadow: none;
  outline: none;
}
