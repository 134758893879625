.day {
  .event {
    .eventEditButton {
      float: right;
      cursor: pointer;
      margin-left: 10px;
    }
    .signupIndicator {
      float: right;
    }
  }
}

@import './EventModal.scss';
