.OnDeckNavigation {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  background: linear-gradient(to top, #161855 0%, #2989d8 87%, #207cca 94%, #969abf 100%);
  color: #fff;
  .navBack {
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 20%;
    font-size: 1.1em;
  }

  .navBack > .glyphicon,
  .navForward > .glyphicon {
    font-weight: bold;
    font-size: 1.1em;
  }

  .navBack:hover,
  .navForward:hover {
    color: #fff;
    font-weight: bold;
  }

  .currentlyViewing {
    font-size: 1.1em;
    font-weight: bold;
    width: 60%;
    text-align: center;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .navForward {
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
    font-size: 1.1em;
  }
}
