.lbuttonbar {
  padding: 0px 8px;
}
.nd-inline-block {
  display: inline-block;
  vertical-align: bottom;
  white-space: nowrap;
}
.nd-single-button {
  margin: 0 1px;
}
.cursor-pointer {
  cursor: pointer;
}

.nd-single-button.nd-inline-block.button-disabled,
.nd-single-button.nd-inline-block.button-disabled,
.nd-single-button.nd-inline-block.undefined.button-disabled,
.nd-single-button.nd-inline-block.undefined.button-disabled,
.nd-single-button.nd-inline-block.btn-default.button-disabled,
.nd-single-button.nd-inline-block.undefined.button-disabled:hover,
.nd-single-button.nd-inline-block.button-disabled:hover {
  button {
    cursor: not-allowed !important;
    color: #ccc;
    background: white;
    border: 2px solid #ccc;
    box-shadow: 2px 2px 5px #a1a1a1;
  }
}
.button-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.hover-class-epr:hover {
  background: #9d5949 !important;
  color: white !important;
}
.hover-class-netduty:hover {
  background: #f8f8f8 !important;
  color: #555 !important;
}
.selected-epr {
  background: #9D5949 !important;
  padding-bottom: 0;
  height: 74px;
  min-width: 100px;
  max-width: 135px;
}
.selected-netduty {
  background: #ebebfa;
  color: #000;
  padding-bottom: 0;
  height: 74px;
  min-width: 100px;
  max-width: 135px;
}
.large-style-epr {
  padding-bottom: 0;
  min-width: 100px;
  max-width: 135px;
  background: #BD6B58 !important;
}

.large-style-netduty {
  padding-bottom: 0;
  min-width: 100px;
  height: 74px;
  max-width: 135px;
}

