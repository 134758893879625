.dashboard, .dashboard-netduty {
  padding: 100px 6px 0px 6px;
  background: #1e1e1e;
  min-height: calc(100vh - 64px); //64px is from header height

  .dashboard-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-x: hidden;
  }

  .column {
    width: 100%;
    height: fit-content;
    padding: 10px;
    background: white;
    .netduty {
      background: white;
    }
    border-radius: 5px;
    margin: 5px 0px;
  }
}
.dashboard-netduty {
  background: #cccccc;
  height: fit-content;
}

@media (min-width: 500px) {
  .dashboard, .dashboard-netduty {
    .column {
      width: auto;
      margin: 10px;
    }
  }
}
