.form {

  .MuiTypography-root {
    font-size: 1.0em;
  }
  .MuiInputBase-root, .MuiButtonBase-root, .MuiFormLabel-root {
    font-size: 13px;
    span {
      font-size: 13px;
    }
  }
  .MuiFormLabel-roots, .MultiValueGeneric {
    font-size: inherit;
  }

  text-align: center;
  .form-sixty-percent {
	  width: 60%;
  }
  .form-eighty-percent {
	  width: 80%;
  }
  .form-full-width {
	  width: 100%;
  }
  .formContent {
    display: inline-block;
    border: 1px solid black;
    border-radius: 5px;
    .formColumns {
      display: flex;
      border-bottom: 1px solid black;
      .formColumn {
        width: 100%;
        display: inline-block;
        border-left: 1px solid black;
      }
      .formColumn:first-child {
        border-left: none;
      }
    }
    .formSection {
      border-top: 1px solid black;
      .sectionLabel {
        border-bottom: 1px solid black;
        font-size: 13px;
      }
      .sectionContent {
        text-align: left;
        padding: 5px 5px;
        overflow-x: auto;
        .table-row {
          display: table-row;
        }
        .rowFields {
          display: flex;
          align-items: flex-end;
          width: 100%;
        }
        .hide {
          display: none;
        }
        .button-blue {
          background: blue !important;
          color: white;
          font-size: 12px;
          border-radius: 5px;
          padding: 7px;
          margin: 0px 4px;
          font-weight: bold;
          border: 2px solid #009ad0;
          box-shadow: 2px 2px 5px #a1a1a1;
        }
        .inputField {
          padding-bottom: 10px;
          .inputLabel {
            vertical-align: top;
            // text-align: right;
          }
          .subLabel {
            text-align: left;
            font-weight: bold;
          }
          input {
            max-width: 480px;
            border-radius: 5px;
            font-size: 12px;
          }
          textarea {
            width: 100%;
            max-width: 480px;
            border-radius: 5px;
            resize: none;
          }
          .formCheckBox {
            // styles for FireFox
            width: auto;
            //float: left;
          }
          .rc-time-picker {
            color: inherit;
            display: block;
            font-size: inherit;
            height: 36px;
            text-transform: uppercase;
            //display: table-cell;
          }
          .dateRange {
            .start {
              margin-bottom: 8px;
            }
          }
          .default-input {
            display: block;
            height: 29px;
            padding-left: 4px;
          }
          .customSelect {
            max-width: 100%;
            width: 230px;
            text-align: left;
            font-size: 12px;

            .optLabel {
              padding: 5px 10px;
              cursor: pointer;

              .detailText {
                font-size: 0.8em;
                font-style: italic;
              }
            }

            .optLabel:hover {
              background-color: #e6f4fe;
            }
            // The class customSelect is passed to <Select in the classNamePrefix opt
            // __menu is then the class for the drdopdown component
            .customSelect__menu {
              position: relative;
            }
          }

        }
        .htmlWrapper {
          // overflow: auto;
          // display: inline-block;
          // width: 100%;
          font-size: 12px;
          .htmlLabel {
            font-weight: bold;
            padding-right: 10px;
            text-align: right;
            vertical-align: top;
          }
          .htmlField {
            text-align: left;
            // min-width: 230px;
          }
        }
        div.Select.customSelect.multiple {
          min-width: 100%;
          max-width: 400px;
        }
        .radioButtons {
          display: inline;
          .radio-wrapper {
            display: inline-block;
            max-width: 320px;
            white-space: nowrap;
            input {
              float: none;
            }
            label {
              float: none;
              font-weight: normal;
              padding: 0px 5px 0px 15px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .formSection:first-child {
      border-top: none;
    }
    .buttonContainer {
      padding: 5px 10px;

      .formButton {
        border-radius: 5px;
        margin: 0px 4px;
        font-weight: bold;
        background: white;
        color: #009ad0;
        border: 2px solid #009ad0;
        box-shadow: 2px 2px 5px #a1a1a1;
      }
      .formButton.nd-button.green {
        border: 2px solid #28a745;
        color: #28a745;
      }
      .formButton.nd-button.red {
        border: 2px solid #dc3545;
        color: #dc3545;
      }
      .formButton.nd-button.blue {
        border: 2px solid #009ad0;
        color: #009ad0;
      }
      .formButton.nd-button.gray {
        color: #7c7c7c;
        border: 2px solid #7c7c7c;
      }
      .formButton.button-disabled,
      .formButton.nd-button.undefined.button-disabled,
      .button-disabled:hover {
        cursor: not-allowed;
        color: #ccc;
        background: white;
        border: 2px solid #ccc;
        box-shadow: 2px 2px 5px #a1a1a1;
      }
    }
    .cornerControlPanel {
      float: right;

      .formButton {
        border-radius: 5px;
        margin: 0px 4px;
        font-weight: bold;
        background: #009ad0;
        border: 1px solid #a1a1a1;
        box-shadow: 2px 2px 5px #a1a1a1;
      }

      .formButton:hover {
        opacity: 0.9;
        color: #fff;
      }

      .button-disabled,
      .button-disabled:hover {
        background: #555;
        cursor: not-allowed;
        opacity: 0.5;
        color: #fff;
      }
    }
  }
}

.react-datepicker-component {
  width: 230px;
}

.form.standAlone {
  .formContent {
    border: none;
    .formColumns {
      border: none;
      .formColumn {
        border: none;
        padding: 10px;
        padding-left: 50px;
      }
      .formColumn:first-child {
        padding-left: 10px;
      }
    }
    .formSection {
      .sectionContent {
        .inputField {
          width: 240px;
        }
      }
    }
  }
  .contentCard {
    display: inline-block;
  }
}

.formDialog {
  font-size: 15px;
  overflow: auto;

  .confirmButton {
    border-radius: 5px;
    margin: 0px 4px;
    font-weight: bold;
    margin-top: 15px;
  }
}

// @import '~react-select/dist/react-select.css';
@import '~rc-time-picker/assets/index.css';
@import '~react-datetime/css/react-datetime.css';

// modifications to rc-datepicker
div.rc-time-picker-panel {
  z-index: 1203; // One more than the DialogBox
  .rc-time-picker-panel-inner {
    max-width: 230px;
    width: 230px;
    .rc-time-picker-panel-select {
      width: 114px;
      overflow: hidden;
      overflow-y: auto;
    }
    .rc-time-picker-panel-select li {
      padding: 0;
      text-align: center;
    }
  }
}

// modifications for react-datetime must come after import
.rdt {
  width: 200px;
  .form-control {
    height: 34px;
    background-color: #fff;
    cursor: pointer;
  }
  .rdtPicker {
    width: auto;
    min-width: fit-content;
    position: relative;

    .rdtDay {
      font-weight: 700;
    }

    .rdtDay.rdtDisabled {
      font-weight: 300;
    }
  }

  .rdtPicker th {
    height: 21px !important;
  }
  .rdtPicker td {
    padding: 4px !important;
  }
}

.Select--multi .Select-multi-value-wrapper {
  max-width: 500px;
}
.Select-placeholder,
.Select--single > .Select-control .Select-value {
  width: 180px;
  overflow: hidden;
}
.Select-arrow-zone,
.Select--multi .Select-clear-zone {
  vertical-align: top !important;
  padding-top: 8px;
}

//Mini Form: This needs more change after
//changing the table simulation for the form

.form.miniForm {
  .formContent {
    border: none;
    display: inline-block;
    .formColumns {
      border: none;
      position: relative;
      .buttonContainer {
        position: absolute;
        bottom: -24px;
        right: 0;
        padding: 0;
        .formButton {
          padding: 4px;
          margin-right: 0;
        }
      }
      .formColumn {
        border: none;
        padding: 0;
        padding-left: 50px;
      }
      .formColumn:first-child {
        padding-left: 10px;
      }
    }
    .formSection {
      .sectionContent {
        display: block;
        padding: 0;
        .inputLabel {
          text-align: left;
          display: block;
        }
        .inputField {
          display: block;
          width: 240px;
        }
        .display-flex-wrap {
          display: flex;
          flex-wrap: wrap;
        }
        .rdt {
          width: auto;
        }
      }
    }
  }
}

// Modifiers
.form-wide {
    width: 700px !important;
}
.form-full-width {
    max-width: 100% !important;
    width: 100% !important;
}


@media (max-width: 480px) {
  .form {
    .formContent {
      display: inline-block;
      border: none;
      .formColumns {
        display: block;
        border-bottom: none;
        .formColumn {
          .formSection {
            .sectionContent {
              .rowFields {
                display: block;
                align-items: inherit;
                width: auto;
              }
            }
          }
          border-left: none;
          display: block;
          padding-left: 0 !important;
          padding: 10px 0 !important;
          .rc-time-picker-input {
            height: 36px;
            font-size: 1.1em;
          }
          textarea {
            display: block;
            max-width: 290px !important;
          }
          .Select.customSelect.multiple.Select--multi.is-clearable.is-searchable {
            min-width: auto;
          }
        }
      }
      .buttonContainer {
        clear: both;
        padding: 5px 0;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        .formButton {
          margin: 0;
          margin-bottom: 6px;
          width: 100%;
          height: 46px;
          font-size: 1.3em;
        }
      }
    }
  }

  .form.standAlone {
    .contentCard {
      max-width: 100%;
      .formContent {
        border: none;
        .formColumns {
          border: none;
          .formColumn {
            border: none;
            padding-left: 0;
            padding: 10px;
          }
          .formColumn:first-child {
            padding-left: 10px;
          }
        }
        .formSection {
          .sectionContent {
            .inputField {
              width: 100%;
            }
          }
        }
      }
    }

    .form-wide {
        width: 100%;
    }

  }

  .login {
    .form.standAlone {
      .contentCard {
        min-width: 300px !important;
        max-width: 300px;
        box-shadow: none;
        .cardBody {
          .formContent {
            width: 100%;
            .MuiFormLabel-root,
            .MuiTextField-root {
              font-size: 1.3em;
            }
            .buttonContainer {
              display: flex;
              flex-wrap: nowrap;
              flex-direction: column;
              .formButton {
                margin: 0;
                margin-bottom: 6px;
                width: 100%;
                height: 46px;
                font-size: 1.3em;
              }
            }
          }
        }
      }
    }
    .MuiTextField-root {
      min-width: 240px;
      width: 100%;
    }
  }
}
