.footer-wrapper {
  margin-top: 10px;
  text-align: center;
  width: 100%;
  bottom: 0;
  position: fixed;
  background-color: white;

  hr {
    margin: 5px 0;
  }
}
