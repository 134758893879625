.day-panel, .day-panel-netduty {
  min-height: 150px;
  .alert-icon {
    color: #ffae42;
  }
  abbr {
    cursor: pointer;
  }
  .top-buttonbar {
    width: 100%;
    padding: 0px 5px;
    .align-right {
      text-align: right;
    }
  }
  #tooltip-right > .tooltip-inner {
    background: #ececec;
    color: #3c3c3c;
  }
  .position-relative {
    position: relative;
  }
  .side-button {
    position: absolute;
    left: -31px;
  }

  .detail-list-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    border-bottom: 1px silver solid;
  }
  li {
    list-style: none;
  }
  .day-content-header-label {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    color: #808080 !important;
  }
  .panel-header {
    font-weight: bold;
    font-size: 1.2em;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    padding: 5px;
    justify-content: space-between;

    .day-number {
      width: 15%;
      font-size: 1.5em;
      padding: 5px;
      margin-right: 5px;
      color: black;
    }
    .day-number.dayview-btn-link {
      cursor: pointer;
    }
    .day-number.dayview-btn-link:hover {
      background: #efefff;
      font-size: 1.55em;
    }
    .day-header-details {
      padding-top: 5px;
      width: 70%;
      text-align: center;
      .day-header-label {
        margin-right: 5px;
      }
    }
    .alert-wrapper {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row-reverse;
      align-items: center;
      align-content: center;
      margin-right: 5px;
      padding-top: 5px;
      width: 30%;
      max-width: 50px;
      text-align: right;
    }
  }
  .panel-body {
    padding-top: 0;
    padding-left: 5px;
    .day-content-group {
      padding-left: 5px;
      width: 100%;
      .list_content_flex {
        display: flex;
        justify-content: space-between;
      }
      .list-contents {
        border-bottom: 1px solid #eaecf0;
        align-items: center;
      }
      .list-content-label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #505050;
        margin: 0px;
      }
      .on-duty-content {
        color: #45b23b;
        font-weight: 500;
      }
      .content-shift-status {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
      .day-content-item {
        margin: 0;
        &:not(:last-child) {
          padding-bottom: 10px;
          margin-bottom: 10px;
          // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .title {
          font-weight: bold;
          font-size: 1.1em;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #808080;
        }
        .detail-list {
          display: block;
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          .header {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
          }
          .detail-label {
            font-weight: bold;
            margin-left: 3px;
            margin-right: 3px;
            color: black;
          }
          li {
            display: flex;
            flex-wrap: nowrap;
            padding: 0px 0px 0px 15px;
            width: 100%;
            .shift-wrapper {
              padding-left: 0;
              margin-left: 15px;
            }
          }
          .records-buttonbar {
            display: flex;
            flex-wrap: nowrap;
            .record-button {
              margin: 0 1px;
              width: 15px;
              height: 15px;
            }
          }
          .records-buttonbar:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .temporary_state {
    background-color: lightgrey !important;
    font-style: italic;
    min-width: 100%;
  }
}
.day-panel {
  background: #fff;
}
.day-panel-netduty + .today {
  background: #cdc4bc;
}
.day-panel-netduty {
  border: 1px solid #3e3e3e;
}

@media (max-width: 500px) {
  .day-panel {
    .panel-header {
      font-size: 1.2em;
    }
  }
}
